.App {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	header {
		min-width: 100vw;
		flex: 1;
		font-size: calc(10px + 2vmin);
		color: white;
	}
	main {
		min-width: 100vw;
		flex: 23;
	}
	.keyboard {
		width: 600px;
		margin: 50px auto;
		.keyboard-row {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			.key {
				color: white;
				font-weight: bold;
				width: 50px;
				height: 40px;
				line-height: 40px;
				display: inline-block;
				border: 1px solid white;
				border-radius: 3px;
				background-color: #383c44;
				transition: background-color .2s;
				&.active {
					color: white;
					font-weight: bold;
					width: 50px;
					height: 40px;
					line-height: 40px;
					display: block;
					border: 1px solid white;
					border-radius: 3px;
					background-color: #585c64;
				}
			}
		}
	}
	.preset-selector {
		display: flex;
		justify-content: center;
		flex-direction: row;
		width: 400px;
		margin: 0 auto;
		.preset {
			display: block;
			height: 120px;
			width: 40px;
			padding: 0 15px;
			border: 1px solid black;
			border-radius: 5px;
			background: #fff;
			text-align: center;
			&.active {
				border: 2px solid orange;
			}
			img {
				width: 100%;
				display: block;
			}
		}
	}
  .knobs {
    display: flex;
    flex-direction: row;
    width: 600px;
    margin: 0 auto;
    justify-content: space-evenly;
    div {
      border: 3px solid black;
      background-color: #444;
      border-radius: 50%;
    }
  }
}
